import { Point, ResponsiveLine, Serie, SliceTooltip } from '@nivo/line';
import { Body } from 'components/text/body';
import React, { useMemo, useCallback } from 'react';
import PaddingBox from 'src/view/components/padding-box';
import { EngagementStat } from '../data/performance';
import scaleBetween from 'src/utils/scale-between';
import { COLORS, FONT } from 'shared/theme';
import Box from '@rexlabs/box';
import dayjs from 'dayjs';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { range } from 'lodash';
import { NIVO_THEME } from 'src/theme/nivo';

const styles = StyleSheet({
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: 5
  },
  graph: {
    // Needed for legend because nivo theme doesn't support
    '& text': {
      fontFamily: `${FONT.FAMILY.PROXIMA_NOVA} !important`
    }
  }
});

interface EngagementStatsProps {
  engagementStats: EngagementStat[];
}

const EngagementStats = ({ engagementStats }: EngagementStatsProps) => {
  const s = useStyles(styles);

  const clicks = engagementStats.map((stat) => stat.clicks);
  const maxClicks = Math.max(...clicks);
  const minClicks = Math.min(...clicks);

  const impressions = engagementStats.map((stat) => stat.impressions);
  const maxImpressions = Math.max(...impressions);
  const minImpressions = Math.min(...impressions);

  const clicksTickValues = useMemo(() => {
    const incrementFloat = (maxClicks - minClicks) / 8; // We just want 8 steps
    const incrementInt = Math.ceil(incrementFloat);
    return range(minClicks, maxClicks, incrementInt);
  }, [maxClicks, minClicks]);

  const impressionsTickValues = useMemo(() => {
    const incrementFloat = (maxImpressions - minImpressions) / 8; // We just want 8 steps
    const incrementInt = Math.ceil(incrementFloat);
    return range(minImpressions, maxImpressions, incrementInt);
  }, [maxImpressions, minImpressions]);

  const renderImpressionsTick = useCallback(
    ({ x, y, opacity, textBaseline, textAnchor, textX, textY, tickIndex }) => {
      return (
        <g transform={`translate(${x}, ${y})`} style={{ opacity }}>
          <text
            alignmentBaseline={textBaseline}
            textAnchor={textAnchor}
            transform={`translate(${textX}, ${textY})`}
            className='axisLegend'
            fontFamily={FONT.FAMILY.PROXIMA_NOVA}
            fontSize={11}
            fill={'#333'}
          >
            {impressionsTickValues[tickIndex]}
          </text>
        </g>
      );
    },
    [impressionsTickValues]
  );

  const chartData = useMemo(() => {
    const mappedClicks: Serie['data'] = [];
    const mappedImpressions: Serie['data'] = [];

    // Normalize values to be mapped in the chart
    if (maxImpressions > maxClicks) {
      engagementStats.forEach((stat) => {
        mappedClicks.push({
          x: stat.time,
          y: stat.clicks,
          yOrig: stat.clicks
        });
        mappedImpressions.push({
          x: stat.time,
          y: scaleBetween(
            stat.impressions,
            minClicks,
            maxClicks,
            minImpressions,
            maxImpressions
          ),
          yOrig: stat.impressions
        });
      });
    } else {
      engagementStats.forEach((stat) => {
        mappedClicks.push({
          x: stat.time,
          y: scaleBetween(
            stat.clicks,
            minImpressions,
            maxImpressions,
            minClicks,
            maxClicks
          ),
          yOrig: stat.clicks
        });
        mappedImpressions.push({
          x: stat.time,
          y: stat.impressions,
          yOrig: stat.impressions
        });
      });
    }

    return [
      {
        id: 'Impressions',
        data: mappedImpressions
      },
      {
        id: 'Clicks',
        data: mappedClicks
      }
    ];
  }, [maxClicks, minClicks, maxImpressions, minImpressions, engagementStats]);

  const sliceTooltip: SliceTooltip = useCallback(
    ({ slice }) => {
      return (
        <PaddingBox white padding={10}>
          <Body dark semibold>
            {dayjs(slice.points[0].data.x).format('DD MMM')}
          </Body>
          <Box spacing={10} mt={5}>
            {slice.points.map((point) => (
              <Box key={point.id} alignItems='center'>
                <div
                  {...s('circle')}
                  style={{ backgroundColor: point.serieColor }}
                />
                <Body dark normal>
                  {point.serieId}{' '}
                  {(point.data as Point['data'] & { yOrig: number }).yOrig}
                </Body>
              </Box>
            ))}
          </Box>
        </PaddingBox>
      );
    },
    [s]
  );

  return (
    <PaddingBox light>
      <Body large dark semibold>
        Engagement
      </Body>
      <PaddingBox mt={15} padding={'0px'} white height={240} {...s('graph')}>
        <ResponsiveLine
          theme={NIVO_THEME}
          data={chartData}
          colors={[
            COLORS.NEW_REX_BRAND.CALENDAR_BLUE,
            COLORS.NEW_REX_BRAND.CORAL_PINK
          ]}
          legends={[
            {
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              translateX: -20,
              translateY: 47,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 65,
              itemHeight: 20,
              symbolSize: 10,
              symbolShape: 'circle',
              itemTextColor: COLORS.DASHBOARDS.DARK
            }
          ]}
          margin={{
            top: 40,
            right: 50,
            bottom: 60,
            left: 50
          }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            max: clicksTickValues[clicksTickValues.length - 1],
            min: clicksTickValues[0]
          }}
          gridYValues={clicksTickValues}
          enableSlices='x'
          enableGridX={false}
          axisTop={null}
          axisRight={{
            tickSize: 0,
            tickPadding: 12,
            legendPosition: 'middle',
            tickValues: clicksTickValues,
            renderTick: renderImpressionsTick
          }}
          axisBottom={{
            tickSize: 5,
            legend: null,
            tickPadding: 0,
            legendOffset: 50,
            // Reduce tick values to make them fit
            tickValues:
              engagementStats.length === 30
                ? engagementStats
                    .map((stat) => stat.time)
                    .filter((_stat, i) => i % 3 === 0)
                : undefined,
            legendPosition: 'middle',
            format: (value) => dayjs(value).format('DD MMM')
          }}
          axisLeft={{
            tickSize: 0,
            tickValues: clicksTickValues,
            tickPadding: 12,
            legendPosition: 'middle'
          }}
          sliceTooltip={sliceTooltip}
          pointSize={5}
          pointColor={'#fff'}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
        />
      </PaddingBox>
    </PaddingBox>
  );
};

export default EngagementStats;
