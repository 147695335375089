import { Body } from 'components/text/body';
import React, { useCallback, useState } from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import PaddingBox from 'src/view/components/padding-box';
import { Popout } from 'src/view/components/popout';
import Box from '@rexlabs/box';
import { TextButton } from 'src/view/components/button';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import { ListingPerformance, MarketingCampaign } from '../data/performance';
import { SubHeading } from 'components/text/sub-heading';

const styles = StyleSheet({
  externalIcon: {
    marginLeft: 2,
    '& svg': {
      width: 8.33,
      height: 8.33
    }
  },
  button: {
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '10px 15px',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.STATES.BG_BLUE_HOVER
    }
  },
  copied: {
    padding: '12px 14px',
    backgroundColor: '#000',
    boxShadow: '1px 1px 5px #AAA',
    '& svg path': {
      stroke: '#fff'
    }
  }
});

interface PortalSelectProps {
  listingPerformance: ListingPerformance;
  selectedPortal: MarketingCampaign;
  setSelectedPortal: (portal: MarketingCampaign) => void;
}

const PortalSelect = ({
  selectedPortal,
  setSelectedPortal,
  listingPerformance
}: PortalSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const s = useStyles(styles);

  const Content = useCallback(() => {
    return (
      <Box width={200} p={5}>
        {listingPerformance.campaigns.map((portal) => (
          <button
            type='button'
            key={portal.id}
            onClick={() => setSelectedPortal(portal)}
            {...s('button')}
          >
            <Body dark normal>
              {portal.source_title}
            </Body>
          </button>
        ))}
      </Box>
    );
  }, [setSelectedPortal, s, listingPerformance.campaigns]);

  const Copied = useCallback(() => {
    return (
      <Box alignItems='center' {...s('copied')}>
        <Icon
          type={ICONS.CHECK}
          width={16}
          height={16}
          style={{ display: 'flex', marginRight: 10 }}
        />
        <Body white normal regular>
          Link copied
        </Body>
      </Box>
    );
  }, [s]);

  return (
    <PaddingBox
      justifyContent='space-between'
      alignItems='center'
      white
      mb={20}
      padding={'14px 25px'}
    >
      <Popout Content={Content}>
        <Box flexDirection='row' alignItems='center' spacing={8}>
          <Icon
            type={ICONS[selectedPortal.id.toUpperCase()]}
            width={'32px'}
            height={'32px'}
            hasControlledColor={false}
          />
          <SubHeading semibold>{selectedPortal.source_title}</SubHeading>
          <Icon
            type={ICONS.ARROW_DOWN}
            width={'32px'}
            height={'32px'}
            style={{ transform: 'rotate(-90deg)', marginLeft: -5 }}
          />
        </Box>
      </Popout>
      <Box alignItems='center' spacing={10}>
        <TextButton blue padding='0px'>
          <Box alignItems='center'>
            {selectedPortal.id === 'reach'
              ? 'view reach portal'
              : 'view live listing'}
            <Icon
              type={ICONS.EXTERNAL_LINK}
              width={'8px'}
              height={'8px'}
              {...s('externalIcon')}
            />
          </Box>
        </TextButton>
        <Popout
          placement='top'
          Arrow={null}
          Content={Copied}
          distance={'0px'}
          arrowMargin={'0px'}
          isOpen={isOpen}
          setOpen={setIsOpen}
          onChange={({ isOpen }) => {
            setIsOpen(isOpen);
            if (isOpen) {
              setTimeout(() => setIsOpen(false), 3000);
            }
          }}
        >
          <TextButton sand padding='0px' style={{ position: 'relative' }}>
            <Icon type={ICONS.COPY_LINK} style={{ display: 'flex' }} />
          </TextButton>
        </Popout>
      </Box>
    </PaddingBox>
  );
};

export default PortalSelect;
