export const COLORS = {
  COOL_GREY: '#f8faff',
  WARM_GREY: '#e6e6e2',
  COAL_GREY: '#404040',
  DARK_GREY: '#424242',
  DARK_GREY_75: 'rgba(66, 66, 66, 0.75)',
  DARK_GREY_80: 'rgba(66, 66, 66, 0.8)',
  DARKER_GREY: '#424757',
  BLUE_GREY: '#838EAE',
  BLUE_GREY_LIGHT: '#E8ECF6',
  FAINT_GREY: '#CCCCC4',

  GREY_FEINT: 'rgba(225,225,225, 0.5)',
  GREY: '#C1C6D6',
  GREY_LIGHT: '#C1C6D6',
  GREY_MEDIUM: '#A1A7B7',
  GREY_DARK: '#424242', // #424242 @ 100% opacity

  BLUE_SKY: '#89CAF7',
  BLUE_DARK: '#2578B2',
  BLUE_DEEP_SKY: '#0CA6C2',

  BLUE_REX: 'rgb(30, 161, 230)',

  BLACK_90_OPACITY: 'rgba(0, 0, 0, 0.9)',
  BLACK_75_OPACITY: 'rgba(0, 0, 0, 0.75)',
  BLACK_50_OPACITY: 'rgba(0, 0, 0, 0.5)',
  BLACK_40_OPACITY: 'rgba(0, 0, 0, 0.4)',
  BLACK_30_OPACITY: 'rgba(0, 0, 0, 0.3)',
  BLACK_10_OPACITY: 'rgba(0, 0, 0, 0.1)',
  BLACK_7_OPACITY: 'rgba(0, 0, 0, 0.07)',

  BLACK: '#000000',

  WHITE_30_OPACITY: 'rgba(255,255,255, 0.3)',

  WHITE: '#FFFFFF',

  APP_SEARCH: {
    TILE_BACKGROUND: '#F3F6FC',
    OPTION_HOVER: '#F8FAFF',
    SPACER: '#EDEDED',
    BOX_SHADOW: 'rgba(90, 89, 89, 0.19)',
    GREY_MEDIUM: '#B3B3B3',
    GREY_SLATE: '#A8AFB4',
    HEADING: '#7f8794'
  },

  NAV_MENU: {
    GREY: '#666', // #666666 @ 100% opacity
    GREY_LIGHT: '#E0E0E0', // #E0E0E0 @ 100% opacity
    GREY_DARK: '#424242', // #424242 @ 100% opacity
    GREY_ULTRA_LIGHT: '#F7F8F9',

    GREY_SLATE: '#9AA4B4',
    GREY_SLATE_DARK: '#79818D',
    GREY_SLATE_LIGHT: '#BCC3CE',
    GREY_SLATE_LIGHT_ALT: '#F4F5F7',
    GREY_SLATE_ULTRA_LIGHT: '#FAFAFB',

    BLUE: '#3AA6F1',
    LIGHT_BLUE: '#6CBCF5',

    GREEN: '#38CA73',
    GREEN_LIGHT: '#69D795',

    RED: '#C11D17'
  },

  BANNER: {
    GREY: '#EAE9E8'
  },

  // TODO: Only use the colors from this line and below
  // Any of the above colours should be updated to use the following if you run into them.

  PRIMARY: {
    BLUE: '#1EA1E6',
    SLATE: '#9AA4B4',
    SLATE_DARK: '#79818D',
    BLACK: '#000000',
    GREY_LIGHTER: '#404040',
    GREY_DARK: '#424242',
    GREY_DARKER: '#333333',
    TAG_GREY: '#F2F3F4',
    RED: '#C11D17',
    SAND: '#929088',
    SAND_LIGHT: '#B3B2AC',
    SAND_DARK: '#929088',
    SAND_HOVER: '#9E9B93',
    WHITE: '#FFFFFF',
    YELLOW: '#F3D200',
    YELLOW_LIGHT: '#FAEA8B',
    SUNSHINE_YELLOW: '#FFF0C7',
    GREEN: '#8BBF3C',
    GREY_BLACK: '#2f2f2f',
    LIGHT_GREY: '#E6E8EC',
    MID_GREY: '#EDEDEB',
    WARM_ORANGE: '#E86B20',
    REX_BLUE: '#3AA6F1',
    INFORMATIVE: '#B4B1A9'
  },
  IMPACT: {
    OPEN: '#1EA1E6',
    HIGH: '#C11D17',
    ACTIVE: '#CAC9C5',
    MEDIUM: '#424242',
    SUCCESS: '#8BBF3C'
  },
  OVERLAY: {
    OVERLAY: 'rgba(0, 0, 0, 0.9)',
    DIALOG: 'rgba(0, 0, 0, 0.2)',
    ROOM_IMAGES_BACKGROUND: 'rgba(216,216,216,0.15)'
  },
  STATES: {
    BLUE: '#CAE8F8',
    SLATE_LIGHT: '#F5F6F8',
    GREY: '#D9D9D4',
    YELLOW: '#F9EC98',
    DARK_YELLOW: '#EED346',
    GREEN: '#CBE3A8',
    SLATE: '#BEC4CF',
    YELLOW_HOVER: '#D9BC00',
    ACTIVE: '#BEBDB8',
    HOVER: '#C8C7C3',
    IDLE: '#D4D3D0',
    RED: '#C11D17',
    RED_HOVER: '#ad0903',
    BLUE_HOVER: '#1582bc',
    BG_BLUE_HOVER: '#bbe3f7'
  },
  STATUS: {
    ACTIVE: '#5ADB6B',
    ACTIVE_LIGHT: '#ADEDB5',
    DANGER: '#E9153B',
    DANGER_LIGHT: '#F4D9D0'
  },
  BACKGROUNDS: {
    WHITE: '#FFFFFF',
    SAND: '#E6E5E3',
    SAND_LIGHT: '#F4F4F1',
    SAND_DARK: '#E9E9E4',
    GREY: '#E9E9E5',
    GREY_LIGHT: '#FCFCFA'
  },
  BORDERS: {
    GREY: '#B4B1A9'
  },
  TEXTS: {
    GREY_LIGHT: '#919087',
    TAG: '#424242'
  },
  INTEREST_LEVEL: {
    COLD_SELECTED: '#1EA1E6',
    COLD_DEFAULT: '#E4E3DF',
    COLD_BORDER: '#1B91CF',
    HOT_SELECTED: '#AC1914',
    HOT_DEFAULT: '#D0CFC9',
    HOT_BORDER: '#9B1612',
    WARM_SELECTED: '#EECA61',
    WARM_DEFAULT: '#DAD9D4',
    WARM_BORDER: '#C3A800',
    HOVER_DEFAULT: '#9E9B93',
    TEXT_DEFAULT: '#929088',
    TEXT_HOVER: '#5F5E5D'
  },

  DATA_VIS: {
    BEST: '#008054',
    GREAT: '#19B459',
    GOOD: '#8DCE46',
    AVERAGE: '#FFD500',
    BAD: '#FCAA65',
    TERRIBLE: '#EF8023',
    WORST: '#E9153B'
  },
  DASHBOARDS: {
    INCREASE: '#14AC51',
    DECREASE: '#DB4322'
  },
  // matching what we currently have in Figma
  SLATE: {
    LIGHT: '#E0E3E8',
    LIGHT_MEDIUM: '#B4BBC7',
    MEDIUM: '#9AA4B4',
    DARK: '#808793',
    MIDNIGHT: '#3F4A55'
  },

  INPUT: {
    BORDER: '#D9D9D4',
    BORDER_ACTIVE: '#C9C8C4',
    OUTLINE: '#C1C1B9',
    DISABLED_BG: '#F0F0F0'
  },

  CORAL_PINK_FAINT: '#FDE9E8',

  CHECKBOX: {
    DISABLED: '#DFDEDC'
  },
  FORM: {
    DULL: '#F2F2F0'
  }
} as const;
