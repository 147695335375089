/* eslint-disable max-lines */
/* eslint-disable camelcase */

import invariant from 'invariant';
import { store } from 'store';
import { parseRouteToUrl, parseUrlToRoute } from '@rexlabs/whereabouts';
import { dateToValue, valueToDate } from 'view/components/input/date-time';
import adminAppointmentTypesModel from 'features/calendar/data/admin-appointment-types';
import dayjs from 'dayjs';
import timeZone from 'dayjs-ext/plugin/timeZone';
import _ from 'lodash';
import { StyleSheet } from '@rexlabs/styling';

import Analytics from 'shared/utils/vivid-analytics';
import { EVENTS } from 'shared/utils/analytics';
import {
  mapFormToRRUle,
  mapRRuleToForm
} from 'view/dialogs/calendar/recur-appointment';
import externalAddressModel from 'data/models/custom/external-address';
import config from 'shared/utils/config';
import { encode } from 'base64-url';

dayjs.extend(timeZone);

let tmpId = 0;

export const DAY_SECONDS = 86400;

export const DAY_MILLISECONDS = 86400e3;

export function getClientTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function convertUtcToLocalDate(date) {
  return dayjs(date).local().toDate();
}

export function mapApiToEvent(apiData, metaData) {
  const { adminAppointmentTypes, userCalendars } = metaData;

  invariant(
    adminAppointmentTypes,
    'You need to define `adminAppointmentTypes` to be able to properly map from API to event.'
  );

  invariant(
    userCalendars,
    'You need to define `userCalendars` to be able to properly map from API to event.'
  );

  // Get calendar and user calendar stubs
  // We might get those from API (on search/read), or not (update),
  // so we just grab them here to be safe!
  const calendarId =
    _.get(apiData, 'calendar.id') ||
    apiData.new_calendar_id ||
    apiData.calendar_id;
  const userCalendar = userCalendars.find(
    (c) => _.get(c, 'calendar.id') === calendarId
  );
  const calendar = _.get(userCalendar, 'calendar');

  // Same for appointment type stub
  const appointmentTypeId =
    _.get(apiData, 'appointment_type.id') || apiData.appointment_type_id;
  const appointmentType = adminAppointmentTypes.find(
    (t) => t.id === appointmentTypeId
  );

  // For event status, we want a stub (for consistency), but
  // only really care about the ID
  const eventStatusId = _.get(apiData, 'status.id') || apiData.event_status_id;

  // Since the event id is ambiguous, we generate a uuid here,
  // so its easier to compare event items to each other (e.g. for
  // optimistic updates)
  const eventId = apiData.id || `tmp-${++tmpId}`;

  // Using `calendar_id` here if available for Form -> Api -> Event
  const uuid = `${eventId}--${apiData.calendar_id || _.get(calendar, 'id')}--0`;

  const isAllDay =
    dayjs(apiData.starts_at.time).isSame(
      dayjs(apiData.starts_at.time).startOf('day')
    ) &&
    dayjs(apiData.ends_at.time).isSame(
      dayjs(apiData.ends_at.time).startOf('day')
    );

  return {
    // NOTE: we store ALL API fields on the event, which should
    // make backwards mapping easier
    ...apiData,

    // Stubs
    calendar,
    appointment_type: appointmentType,
    event_status: { id: eventStatusId },

    // Extra data
    userCalendar,
    allDay: isAllDay,
    start: convertUtcToLocalDate(apiData.starts_at.time),
    end: convertUtcToLocalDate(apiData.ends_at.time),
    uuid
  };
}

export function getTmpEventId(eventId) {
  const match = eventId && eventId.match(/^(tmp-\d+)--/);
  return match ? match[1] : undefined;
}

export function mapApiToEvents(apiData, metaData) {
  const { currentView } = metaData;
  const events = [];

  const mainEvent = mapApiToEvent(apiData, metaData);
  const travelTime = _.get(apiData, 'travel_time_minutes')
    ? parseInt(apiData.travel_time_minutes)
    : 0;

  if (
    travelTime > 0 &&
    !mainEvent.allDay &&
    currentView !== 'month' &&
    currentView !== 'split_week' &&
    currentView !== 'split_work_week'
  ) {
    mainEvent.start = dayjs(mainEvent.start)
      .subtract(travelTime, 'minutes')
      .toDate();
  }

  events.push(mainEvent);
  return events;
}

function mapApiToRecord(record, modelMap) {
  record.model = _.get(modelMap, _.get(record, 'service'));
  return record;
}

export function mapApiToForm(apiData, modelMap) {
  const {
    description,
    title,
    private_note,
    appointment_type,
    calendar,
    records,
    recurrence_rule,
    id
  } = apiData;

  const clientTimezone = getClientTimezone();

  const followUpReminder = _.get(apiData, 'followup_reminder');

  const hasReminderBeenCreated = followUpReminder?.reminder_id;

  return {
    ...apiData,
    id,
    title,
    description,
    private_note,
    rule: mapRRuleToForm(recurrence_rule),
    is_recurring: !!recurrence_rule,
    event_status_id:
      _.get(apiData, 'event_status.id') || apiData.event_status_id,
    account: apiData.account,
    calendar_id: apiData.calendar_id || _.get(calendar, 'id'),
    original_calendar_id: apiData.calendar_id || _.get(calendar, 'id'),
    appointment_type: _.get(apiData, 'appointment_type.id')
      ? {
          value: _.get(apiData, 'appointment_type.id'),
          label: _.get(apiData, 'appointment_type.name'),
          model: adminAppointmentTypesModel,
          // TODO: For some reason, we're assigning the whole calendar event to the data.
          // This should just be the appointment type, so it matches the select item
          // https://app.clubhouse.io/rexlabs/story/53904/appointment-type-in-calendar-event
          data: apiData,
          appointment_type
        }
      : null,
    starts_at: dateToValue(
      dayjs(_.get(apiData, 'starts_at.time')).format('YYYY-MM-DDTHH:mm:ssZ', {
        timeZone: _.get(apiData, 'starts_at.tzid') || clientTimezone
      }),
      _.get(apiData, 'starts_at.tzid') || clientTimezone
    ),
    ends_at: dateToValue(
      dayjs(_.get(apiData, 'ends_at.time')).format('YYYY-MM-DDTHH:mm:ssZ', {
        timeZone: _.get(apiData, 'ends_at.tzid') || clientTimezone
      }),
      _.get(apiData, 'ends_at.tzid') || clientTimezone
    ),
    // If there is no timezone set, default to the current timezone on the client
    // This should only occur if a new event is being made
    start_time_zone: _.get(apiData, 'starts_at.tzid') || clientTimezone,
    end_time_zone: _.get(apiData, 'ends_at.tzid') || clientTimezone,
    freebusy: {
      id: _.get(apiData, 'transparency.id') === 'opaque' ? 'busy' : 'free'
    },
    event_location: _.get(apiData, 'event_location')
      ? {
          value: _.get(apiData, 'event_location.description'),
          label: _.get(apiData, 'event_location.description'),
          model: externalAddressModel,
          data: {
            ...apiData.event_location
          }
        }
      : null,
    show_private_note: !!apiData.private_note,
    organiser_user: _.get(apiData, 'organiser_user'),
    records: (records || []).map((v) => mapApiToRecord(v, modelMap)),
    alerts: _.get(apiData, 'alerts') || [],
    external_guests: _.get(apiData, 'external_guests') || [],
    guest_calendars: (_.get(apiData, 'guest_calendars') || []).map(
      (calendar) => ({ ...calendar, service: 'Users' })
    ),
    organiser_name:
      _.get(apiData, 'organiser_name') || _.get(calendar, 'owner.name'),
    organiser_email:
      _.get(apiData, 'organiser_email') || _.get(calendar, 'owner.email'),
    sync_event_status: _.get(apiData, 'sync_event_status'),
    remote_permission_can_update: _.get(
      apiData,
      'remote_permission_can_update'
    ),
    remote_permission_can_delete: _.get(
      apiData,
      'remote_permission_can_delete'
    ),
    followup_reminder_action:
      followUpReminder && !hasReminderBeenCreated ? 'update' : '',
    followup_reminder_type_id: _.get(followUpReminder, 'type.id'),
    followup_reminder_due_date_type_id: _.get(
      followUpReminder,
      'due_date_type.id'
    ),
    followup_reminder_custom_due_date:
      _.get(followUpReminder, 'due_date_type.id') === 'custom'
        ? _.get(followUpReminder, 'custom_due_date')
        : undefined
  };
}

export function mapEventToApi(eventData) {
  const {
    calendar,
    appointment_type,
    appointment_type_id,
    description,
    recurrence_rule,
    organiser_user_id,
    location,
    id,
    followup_reminder,
    ...almostApiData
  } = eventData;

  const calendarId = almostApiData.calendar_id || _.get(calendar, 'id');
  const rule = _.get(eventData, 'rule') || null;

  if (rule?.repeats_until) {
    rule.repeats_until = dayjs.unix(rule.repeats_until).format('YYYY-MM-DD');
  }

  // TODO: This function really needs to be refactored... there is alot of overflow with
  // mapFormtoApi. We can probably do most of the work in the one function, but this will
  // require a bit of investigation and proper testing in place to make sure it works from
  // all the locations you can add an event. Currently, this function is only run when adding
  // an event from the calendar screen
  // https://app.clubhouse.io/rexlabs/story/54983/calendar-mapeventtoapi-and-mapformtoapi-have-a-lot-of-crossover

  const apiData = {
    id,
    title: _.get(eventData, 'title') || _.get(appointment_type, 'name'),
    appointment_type_id: _.get(appointment_type, 'id') || appointment_type_id,
    // With the current timezone implementation there will always be a default tzid value
    // If none is set by the user, so this is safe to simply add to the apiData
    // Time data needs to be converted to the event timezone, as compared to the client timezone
    starts_at: {
      time: dayjs(eventData.start).format('YYYY-MM-DDTHH:mm:ssZ', {
        timeZone: eventData.starts_at.tzid
      }),
      tzid: eventData.starts_at.tzid
    },
    ends_at: {
      time: dayjs(eventData.end).format('YYYY-MM-DDTHH:mm:ssZ', {
        timeZone: eventData.ends_at.tzid
      }),
      tzid: eventData.ends_at.tzid
    },
    calendar_id: calendarId,
    new_calendar_id:
      calendarId !== almostApiData.new_calendar_id
        ? almostApiData.new_calendar_id
        : undefined,
    organiser_user_id,
    rule,
    location_id: location?.id || eventData?.location_id,
    transparency_id: eventData?.transparency_id,
    description,
    recurrence_rule,
    private_note: eventData?.private_note || null,
    is_recurring: eventData?.is_recurring,
    event_location: eventData?.event_location || null,
    records:
      _.map(eventData?.records, (r) => ({
        id: r.id,
        service: r.service
      })) || null,
    external_guests: eventData?.external_guests || [],
    guest_calendars: eventData?.guest_calendars || [],
    update_recurring_events: eventData?.update_recurring_events || false,
    followup_reminder,
    guests_confirmed: eventData?.guests_confirmed || false,
    vendors_confirmed: eventData?.vendors_confirmed || false
  };

  delete apiData.start;

  return apiData;
}

export function mapFormToApi(formData) {
  const { id, title, description, private_note, event_status_id, location } =
    formData;

  const clientTimezone = getClientTimezone();

  const startsAtTZ = dayjs(valueToDate(formData.starts_at)).format('Z', {
    timeZone: formData?.start_time_zone || clientTimezone
  });
  const startsAtTime = dayjs(valueToDate(formData.starts_at)).format(
    'YYYY-MM-DDTHH:mm:ss'
  );
  const endsAtTZ = dayjs(valueToDate(formData.ends_at)).format('Z', {
    timeZone: formData?.end_time_zone || clientTimezone
  });
  const endsAtTime = dayjs(valueToDate(formData.ends_at)).format(
    'YYYY-MM-DDTHH:mm:ss'
  );

  const apiData = {
    id,
    title,
    description,
    private_note,
    event_status: { id: event_status_id },
    transparency_id:
      formData?.freebusy?.id === 'busy' ? 'opaque' : 'transparent',
    appointment_type_id: formData?.appointment_type?.value || null,
    starts_at: {
      time: `${startsAtTime}${startsAtTZ}`,
      tzid: formData?.start_time_zone || clientTimezone
    },
    ends_at: {
      time: `${endsAtTime}${endsAtTZ}`,
      tzid: formData?.end_time_zone || clientTimezone
    },
    // Sending label so it's the same value in the input on edit that we put in on save
    event_location: formData?.event_location?.label
      ? {
          description: formData?.event_location?.label,
          latitude: formData?.event_location.data.latitude,
          longitude: formData?.event_location.data.longitude
        }
      : null,
    recurrence_rule: formData?.is_recurring
      ? mapFormToRRUle(
          formData?.rule,
          dayjs(valueToDate(formData.starts_at))
        ).toString()
      : null,
    calendar_id: formData.id
      ? formData.original_calendar_id
      : formData.calendar_id,
    new_calendar_id:
      formData.id && formData.original_calendar_id !== formData.calendar_id
        ? formData.calendar_id
        : undefined,
    location_id: location?.id,
    is_recurring: formData?.is_recurring,
    external_guests: formData?.external_guests || [],
    guest_calendars: formData?.guest_calendars || [],
    records: formData?.records,
    update_recurring_events: formData?.update_recurring_events || false,
    has_changed_organiser: formData?.has_changed_organiser,
    organiser_user_id: formData?.organiser_user?.id,
    followup_reminder: formData?.followup_reminder,
    guests_confirmed: formData?.guests_confirmed || false,
    vendors_confirmed: formData?.vendors_confirmed || false,
    lead_id: formData?.lead_id || undefined
  };

  return apiData;
}

export function mapFormToEvent(formData, metaData) {
  // Utilise other mapping functions here!
  const apiData = mapFormToApi(formData);
  return mapApiToEvent(apiData, metaData);
}

export const EVENT_TYPES = {
  OPEN_HOME: 2,
  AUCTION: 3
};

export const EVENT_CATEGORY_TYPES = {
  PRIVATE_INSPECTION: 'private_inspection',
  UNACCOMPANIED_INSPECTION: 'unaccompanied_inspection'
};

export function isRemoteEditableEvent(event) {
  return event?.remote_permission_can_update;
}

export function isSystemEventTypeId(typeId) {
  return [EVENT_TYPES.OPEN_HOME, EVENT_TYPES.AUCTION].includes(typeId);
}

export function isSystemEvent(event) {
  const typeId = _.get(event, 'appointment_type.id');
  return isSystemEventTypeId(typeId);
}

export function canModifyEvent(event) {
  return (
    !isSystemEvent(event) &&
    isRemoteEditableEvent(event) &&
    _.get(event, 'security_user_rights', []).includes('update')
  );
}

export function getEditMode(eventData, officeDetails) {
  if (!eventData || !eventData.id) {
    // This is a new event, so give the user full edit rights!
    return 'default';
  }

  // privileges stored under _security_user_rights when accessed from dashboard
  const permissions = _.get(
    eventData,
    'security_user_rights',
    _.get(eventData, '_security_user_rights', [])
  );

  if (!permissions || permissions.includes('read_availability')) {
    // No read rights on the event
    // This permission will only appear if the user can't view details
    // In which case they won't be able to edit either
    return 'none';
  }

  const hasUpdatePrivileges =
    !!store.getState()?.session?.privileges?.['calendars.update_event'];

  // external flag to determine if you can edit remote events
  const isRemoteEditable = isRemoteEditableEvent(eventData);
  if (!isRemoteEditable) {
    return 'read';
  }

  if (permissions.includes('update') && hasUpdatePrivileges) {
    const eventAccountId = _.get(eventData, 'account.id');
    const accountId = _.get(officeDetails, 'id');

    const isOwnAccount = parseInt(eventAccountId) === parseInt(accountId);

    // If the event is associated with another account but the user has
    // update rights, we only disable certain fields (+ allow them to
    // transfer the event to the current account)
    if (eventAccountId && !isOwnAccount) {
      return 'other';
    }

    // default = edit rights
    return 'default';
  }

  // Fall back to "read" rights just in case we get an unexpected
  // value for the permissions
  return 'read';
}

export function categoriseAlerts(alerts) {
  const sortedAlerts = {};

  // If we only have recipient_type of 'all', don't bother with extra filtering
  if (_.get(alerts, '0.recipient_type.id') === 'all') {
    [sortedAlerts.allUsersSms, sortedAlerts.allUsersEmail] = (
      alerts || []
    ).reduce(
      (all, alert) => {
        alert.alert_type.id === 'sms' && all[0].push(alert);
        alert.alert_type.id === 'email' && all[1].push(alert);
        return all;
      },
      [[], []]
    );
  } else {
    [
      sortedAlerts.userSms,
      sortedAlerts.userEmail,
      sortedAlerts.contactSms,
      sortedAlerts.contactEmail
    ] = (alerts || []).reduce(
      (all, alert) => {
        alert.recipient_type.id === 'user' &&
          alert.alert_type.id === 'sms' &&
          all[0].push(alert);
        alert.recipient_type.id === 'user' &&
          alert.alert_type.id === 'email' &&
          all[1].push(alert);
        alert.recipient_type.id === 'contact' &&
          alert.alert_type.id === 'sms' &&
          all[2].push(alert);
        alert.recipient_type.id === 'contact' &&
          alert.alert_type.id === 'email' &&
          all[3].push(alert);
        return all;
      },
      [[], [], [], []]
    );
  }

  return sortedAlerts;
}

export const separateUserCalendars = _.memoize((calendars, userId) => {
  // Separate the user calendars into those that are owned by the specified
  // user and those that are not and add a formatted label property.
  const [ownedCalendars, otherCalendars] = calendars.reduce(
    (all, calendar) => {
      let [ownedCalendars, otherCalendars] = all;
      const ownerId = parseInt(_.get(calendar, 'calendar.owner_user.id'));
      const calendarName = _.get(calendar, 'calendar.name');

      if (ownerId === parseInt(userId)) {
        const label = _.get(calendar, 'is_default')
          ? `${calendarName} (Default)`
          : calendarName;
        ownedCalendars = [...ownedCalendars, { ...calendar, label }];
      } else {
        const ownerName = _.get(calendar, 'calendar.owner_user.name');
        const label =
          ownerName === calendarName
            ? ownerName
            : `${ownerName} (${calendarName})`;
        otherCalendars = [...otherCalendars, { ...calendar, label }];
      }

      return [ownedCalendars, otherCalendars];
    },
    [[], []]
  );
  // Sort the calendars alphabetically, with the user's default calendar moved
  // to first place, regardless of its name.
  return [
    _.sortBy(ownedCalendars, (calendar) =>
      _.get(calendar, 'is_default') ? '' : _.get(calendar, 'calendar.name')
    ),
    _.sortBy(otherCalendars, (calendar) => _.get(calendar, 'calendar.name'))
  ];
});

export function makeTetherStyles({
  enterAnimationRight,
  exitAnimationRight,
  enterAnimationLeft,
  exitAnimationLeft
}) {
  return StyleSheet({
    openingRight: {
      animation: `${enterAnimationRight} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
    },

    closingRight: {
      animation: `${exitAnimationRight} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
    },

    openingLeft: {
      animation: `${enterAnimationLeft} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
    },

    closingLeft: {
      animation: `${exitAnimationLeft} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
    },

    content: {
      transition: 'none',
      transformOrigin: 'center'
    }
  });
}

export function connectCalendarToExternal(
  calendarId,
  calendarName,
  connectableCalendars
) {
  Analytics.track({
    event: EVENTS.CALENDAR.CONNECT_EXTERNAL,
    options: { integrations: { Intercom: true } }
  });
  return connectableCalendars
    .getLoginFlowUrl({
      redirect_uri: `${config.REX_APP_URL}/calendar/connect`
    })
    .then((res) => {
      // HACK: currently needed because whereabouts strips the origin
      // will see if we can add that behaviour to whereabouts to ensure there's
      // no other breaking changes elsewhere
      // https://app.shortcut.com/rexlabs/story/61230
      const urlObj = new URL(res.data.authorisation_uri);
      const authRoute = parseUrlToRoute(res.data.authorisation_uri);

      // We need to extend the state in the query params, because cronofy
      // removes everything else from the url and we don't want to store all
      // the state information in the path
      authRoute.query.state = `${authRoute.query.state}|EXT|${encode(
        `${calendarId}|${calendarName}`
      )}`;

      const authUri = parseRouteToUrl(authRoute);
      window.location.href = `${urlObj}/${authUri.substr(1, authUri.length)}`;
    });
}

export function getRemoteCalendar(data) {
  if (!data) return;

  return _.isPlainObject(_.get(data, 'remote_calendar'))
    ? _.get(data, 'remote_calendar')
    : null;
}
