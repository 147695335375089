import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

import UsersOption from 'view/components/input/select/options/users';
import UsersValue from 'view/components/input/select/values/users';
import type { SelectItem } from 'types/select';

export type { SelectItem };
export interface AccountUsersModel {
  errors: null;
  fetch: Function;
  items: SelectItem[];
  realStatus: string;
  status: string;
}

export interface UserItem {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  email_address: string;
}

export interface UserItemCopy {
  id: string;
  _name: string;
  _first_name: string;
  _last_name: string;
  _email_address: string;
}

export interface FullUserItem extends UserItem {
  is_account_user: 1 | 0;
  phone_direct: string;
  phone_mobile: string;
  position: string | null;
  profile_image: string | null;
}

const TYPE = 'accountUsers';
const accountUsersModel = new Generator<SelectItem>(
  TYPE
).createSystemListModel();

// NOTE: we need to define an autocomplete method for cases where we want
// to use the account users value list in an entity select (e.g. when used
// together with contacts)
accountUsersModel.select = {
  autocomplete: (searchTerm) =>
    api
      .post('SystemValues::autocompleteCategoryValues', {
        list_name: 'account_users',
        search_string: searchTerm
      })
      .then(({ data }) => {
        return _.map(data.result, (user) => ({
          value: user.id,
          label: user.text,
          model: accountUsersModel,
          data: user
        }));
      }),
  Value: UsersValue,
  Option: UsersOption
};

export { TYPE };

export default accountUsersModel;
