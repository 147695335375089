import React, { useState, useEffect } from 'react';
import PortalSelect from './components/portal-select';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';
import PrimaryStats from './components/primary-stats';
import {
  ListingPerformance,
  MarketingCampaign,
  mockListingPerformanceResponse
} from './data/performance';
import SecondaryStats from './components/secondary-stats';
import EngagementStats from './components/engagement-stats';

const PortalStats = () => {
  const [listingPerformance, setListingPerformance] =
    useState<ListingPerformance | null>(null);
  const [selectedPortal, setSelectedPortal] =
    useState<MarketingCampaign | null>(null);
  const [range, setRange] = useState('total');

  useEffect(() => {
    setListingPerformance(mockListingPerformanceResponse.listing);
    setSelectedPortal(mockListingPerformanceResponse.listing.campaigns[0]);
  }, []);

  if (!listingPerformance || !selectedPortal) return null;

  return (
    <Box pt={20} style={{ borderTop: `2px solid ${COLORS.BORDERS.GREY}` }}>
      <PortalSelect
        listingPerformance={listingPerformance}
        selectedPortal={selectedPortal}
        setSelectedPortal={setSelectedPortal}
      />
      <PrimaryStats
        listingPerformance={listingPerformance}
        range={range}
        setRange={setRange}
        selectedPortal={selectedPortal}
      />
      {selectedPortal.engagement_stats.length > 0 && (
        <EngagementStats engagementStats={selectedPortal.engagement_stats} />
      )}
      <SecondaryStats selectedPortal={selectedPortal} />
    </Box>
  );
};

export default PortalStats;
