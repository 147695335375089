import { StyleSheet, useStyles } from '@rexlabs/styling';
import { ValueListItem } from 'data/models/value-lists/value-list';
import React from 'react';
import { COLORS } from 'src/theme';

const styles = StyleSheet({
  container: {
    display: 'flex',
    width: 'max-content'
  },
  button: {
    padding: '0 6px',
    margin: '0px',
    border: 'solid 2px #d9d9d4',
    backgroundColor: '#d9d9d4',
    fontWeight: 600,
    textAlign: 'center',
    color: '#a1a19e',
    fontSize: '13px',
    cursor: 'pointer',
    borderStyle: 'solid',
    lineHeight: '31px',
    height: '35px',
    '&:first-child': {
      borderRadius: '4px 0px 0px 4px'
    },
    '&:last-child': {
      borderRadius: '0px 4px 4px 0px'
    }
  },
  buttonActive: {
    borderColor: '#1c94d4',
    backgroundColor: COLORS.PRIMARY.BLUE,
    color: COLORS.WHITE
  }
});

interface ToggleButtonProps {
  options: ValueListItem[];
  selected: string;
  setSelected: (id: string) => void;
}

const ToggleButton = ({
  options,
  selected,
  setSelected
}: ToggleButtonProps) => {
  const s = useStyles(styles);

  return (
    <div {...s('container')}>
      {options.map((option) => (
        <button
          {...s('button', {
            buttonActive: selected === option.id
          })}
          key={option.id}
          type='button'
          onClick={() => setSelected(option.id)}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default ToggleButton;
