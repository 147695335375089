import { Theme as NivoTheme } from '@nivo/core';
import { COLORS, FONT } from 'shared/theme';

export const NIVO_THEME: NivoTheme = {
  labels: {
    text: {
      fontFamily: FONT.FAMILY.PROXIMA_NOVA
    }
  },
  axis: {
    legend: {
      text: {
        fill: COLORS.DASHBOARDS.DARK,
        fontFamily: FONT.FAMILY.PROXIMA_NOVA
      }
    },
    ticks: {
      text: {
        fill: COLORS.DASHBOARDS.DARK,
        fontFamily: FONT.FAMILY.PROXIMA_NOVA
      }
    }
  }
};
