import faker from 'faker';

type Stats = {
  id: string;
  value: number | string | undefined | null;
  label: string;
  dynamicSizing?: boolean;
};

export enum CampaignStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending'
}

export type EngagementStat = {
  clicks: number;
  impressions: number;
  time: string;
};

export type MarketingCampaign = {
  id: string;
  source_title: string;
  url: string;
  status: CampaignStatus;
  primary_stats: Stats[];
  secondary_stats: {
    id: string;
    title: string;
    data: Stats[];
  }[];
  engagement_stats: EngagementStat[];
};

export type ListingPerformance = {
  id: string;
  last_updated: string;
  campaigns: MarketingCampaign[];
};

export type PerformanceData = {
  listing: ListingPerformance;
};

function mockPrimaryStats() {
  return [
    {
      id: faker.random.uuid(),
      value: faker.random.number(200),
      label: 'Appeared in search results'
    },
    {
      id: faker.random.uuid(),
      value: faker.random.number(200),
      label: 'Property page views'
    },
    {
      id: faker.random.uuid(),
      value: faker.random.number(200),
      label: 'Property saves'
    },
    {
      id: faker.random.uuid(),
      value: faker.random.number(200),
      label: 'Email enquiries'
    }
  ];
}

const mockPortalStats = [
  {
    id: faker.random.uuid(),
    title: 'Engagement',
    data: [
      {
        id: faker.random.uuid(),
        label: 'Email enquiries',
        value: faker.random.number(200)
      },
      {
        id: faker.random.uuid(),
        label: 'Email to a friend',
        value: faker.random.number(200)
      },
      {
        id: faker.random.uuid(),
        label: 'Photo views',
        value: faker.random.number(200)
      },
      {
        id: faker.random.uuid(),
        label: 'Shared this property type',
        value: faker.random.number(200)
      }
    ]
  },
  {
    id: faker.random.uuid(),
    title: 'eBrochure Performance',
    data: [
      {
        id: faker.random.uuid(),
        label: 'Click-through rate',
        value: '36%'
      },
      {
        id: faker.random.uuid(),
        label: 'Additional property page views',
        value: faker.random.number(200)
      },
      {
        id: faker.random.uuid(),
        label: 'eBrochures sent',
        value: faker.random.number(200)
      }
    ]
  },
  {
    id: faker.random.uuid(),
    title: 'Campaign Details',
    data: [
      {
        id: faker.random.uuid(),
        label: 'Date listed',
        value: '29 September 2023'
      },
      {
        id: faker.random.uuid(),
        label: 'Rotation rate',
        value: '15 days'
      },
      {
        id: faker.random.uuid(),
        label: 'Days on site',
        value: '21 days'
      },
      {
        id: faker.random.uuid(),
        label: 'Last updated',
        value: '6 October 2023'
      }
    ]
  }
];

export const mockListingPerformanceResponse = {
  listing: {
    id: faker.random.uuid(),
    last_updated: '6 October 2024',
    campaigns: [
      {
        id: 'rea',
        source_title: 'realestate.com.au',
        url: 'https://www.example.com',
        status: CampaignStatus.Active,
        primary_stats: mockPrimaryStats(),
        secondary_stats: mockPortalStats,
        engagement_stats: []
      },
      {
        id: 'domain',
        source_title: 'Domain',
        url: 'https://www.example.com',
        status: CampaignStatus.Active,
        primary_stats: mockPrimaryStats(),
        secondary_stats: mockPortalStats,
        engagement_stats: []
      },
      {
        id: 'reach',
        source_title: 'Reach',
        url: 'https://www.example.com',
        status: CampaignStatus.Active,
        primary_stats: mockPrimaryStats(),
        engagement_stats: Array.from({ length: 30 }).map((_x, idx) => {
          const clicks = faker.random.number(200);
          return {
            clicks: clicks,
            impressions: clicks + faker.random.number(1000),
            time: `01/${idx + 1 > 9 ? idx + 1 : `0${idx + 1}`}/2024`
          };
        }),
        secondary_stats: mockPortalStats
      }
    ]
  }
};
